@import "~react-bulma-components/src/index.sass";

@keyframes rotateColor {
  from {
    filter: invert(50%) grayscale(100%) brightness(50%) sepia(100%) saturate(400%) contrast(200%) hue-rotate(0deg);
  }
  to {
    filter: invert(50%) grayscale(100%) brightness(50%) sepia(100%) saturate(400%) contrast(200%) hue-rotate(360deg);
  }
}

.marker {
  border-radius: 1000em;
  width: 12px;
  height: 12px;
  border: 2px solid black;
  box-shadow: 0px 0px 0px 2px white;
}

.marker.red { background: #ff3860; }
.marker.yellow { background: #ffdd57; }
.marker.green { background: #23d160; }
.marker.blue { background: #209cee; }
.marker.light { background: #f5f5f5; }

.artifact-icon {
  animation: rotateColor;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 5s;
}
